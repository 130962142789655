<template>
  <add-tenant-field
    class="field-with-options"
    :rules="rules"
    :label="label"
    :label-for="id"
    :name="name"
  >
    <div class="field-with-options__input-wrapper">
      <bg-input
        :id="id"
        v-model="input"
        :placeholder="placeholder"
        :disabled="disabled"
        @focus="handleFocus"
        @blur="handleInputBlur"
      />

      <template v-if="isShowOptionsElement">
        <ul v-if="isLoading" class="field-with-options__options">
          <li>
            <bg-dropdown-item
              >Mohon menunggu, sedang memuat data.</bg-dropdown-item
            >
          </li>
        </ul>

        <ul
          class="field-with-options__options"
          v-else-if="isLoaded && !options.length"
        >
          <li>
            <bg-dropdown-item>Data tidak ditemukan.</bg-dropdown-item>
          </li>
        </ul>

        <ul
          v-else-if="options.length"
          class="field-with-options__options"
          data-testid="fieldWithOptions-options"
        >
          <li
            v-for="option in options"
            :key="option.id"
            :class="[
              'field-with-options__options-item',
              {
                'field-with-options__options-item--selected':
                  valueId === option.id,
              },
            ]"
          >
            <bg-dropdown-item
              @click="select(option)"
              class="field-with-options__options-item-dropdown"
            >
              {{ option.label }}
            </bg-dropdown-item>
          </li>
        </ul>
      </template>
    </div>
  </add-tenant-field>
</template>

<script>
import { BgDropdownItem, BgInput } from 'bangul-vue';
import AddTenantField from '../AddTenantField';

const INPUT_BLUR_ACTION_DELAY = 250;

export default {
  name: 'AddTenantFieldWithOptions',
  components: {
    BgDropdownItem,
    BgInput,
    AddTenantField,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    valueId: {
      type: Number,
      default: 0,
    },
    rules: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: this.value,
      isShowOptions: false,
    };
  },
  watch: {
    input(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.input = value;
    },
  },
  computed: {
    isShowOptionsElement() {
      return !!(this.isShowOptions && this.input);
    },
  },
  methods: {
    handleInputBlur() {
      this.$emit('blur');

      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isShowOptions) {
            this.isShowOptions = false;
          }
        }, INPUT_BLUR_ACTION_DELAY);
      });
    },
    handleFocus() {
      this.$emit('focus');
      this.isShowOptions = true;
    },
    select(option) {
      this.$emit('select', option);
    },
  },
};
</script>

<style lang="scss" scoped src="./AddTenantFieldWithOptions.scss"></style>
