import { RENT_TYPE_DATA } from '../constants/BookingData';
import dayjs from './dayjs';

export const getCheckoutDateEstimation = ({
  checkinDate,
  duration,
  rentCountType,
} = {}) => {
  if (checkinDate && duration && RENT_TYPE_DATA[rentCountType]) {
    const { countUnit, countUnitValue } = RENT_TYPE_DATA[rentCountType];
    const relativeUnitDuration = countUnitValue * duration;

    if (dayjs(checkinDate).isValid()) {
      const checkin = dayjs(checkinDate).toDate();

      if (countUnit === 'month') {
        return new Date(
          checkin.setMonth(checkin.getMonth() + relativeUnitDuration)
        );
      } else if (countUnit === 'day') {
        return new Date(
          checkin.setDate(checkin.getDate() + relativeUnitDuration)
        );
      } else if (countUnit === 'year') {
        return new Date(
          checkin.setFullYear(checkin.getFullYear() + relativeUnitDuration)
        );
      }
    }
  }

  return null;
};
