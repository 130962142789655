import _findKey from 'lodash/findKey';

export const GENDER_OPTIONS = [
  {
    label: 'Laki-laki',
    val: 'male',
  },
  {
    label: 'Perempuan',
    val: 'female',
  },
];

export const JOB_OPTIONS = [
  {
    label: 'Karyawan',
    val: 'kerja',
  },
  {
    label: 'Mahasiswa',
    val: 'kuliah',
  },
  {
    label: 'Lainnya',
    val: 'lainnya',
  },
];

export const RENT_TYPE = {
  weekly: 'weekly',
  monthly: 'monthly',
  quarterly: 'quarterly',
  semiannually: 'semiannually',
  yearly: 'yearly',
};

export const RENT_TYPE_MAMIPAY_ALIAS = {
  weekly: 'week',
  monthly: 'month',
  quarterly: '3_month',
  semiannually: '6_month',
  yearly: 'year',
};

export const convertMamipayRentType = rentType =>
  _findKey(RENT_TYPE_MAMIPAY_ALIAS, alias => alias === rentType) || '';

export const RENT_TYPE_DATA = {
  [RENT_TYPE.weekly]: {
    countUnit: 'day',
    countUnitValue: 7,
  },
  [RENT_TYPE.monthly]: {
    countUnit: 'month',
    countUnitValue: 1,
  },
  [RENT_TYPE.quarterly]: {
    countUnit: 'month',
    countUnitValue: 3,
  },
  [RENT_TYPE.semiannually]: {
    countUnit: 'month',
    countUnitValue: 6,
  },
  [RENT_TYPE.yearly]: {
    countUnit: 'year',
    countUnitValue: 1,
  },
};

export const RENT_TYPE_OPTIONS = [
  { label: 'Per Minggu', val: RENT_TYPE.weekly },
  { label: 'Per Bulan', val: RENT_TYPE.monthly },
  { label: 'Per 3 Bulan', val: RENT_TYPE.quarterly },
  { label: 'Per 6 Bulan', val: RENT_TYPE.semiannually },
  { label: 'Per Tahun', val: RENT_TYPE.yearly },
];

const composeRentDurationOptions = ({
  unitLabel = '',
  step = 1,
  maxValue = 12,
} = {}) =>
  new Array(maxValue / step).fill(0).map((_, i) => {
    const value = i * step + step;

    return { label: `${value} ${unitLabel}`, val: i + 1, relativeValue: value };
  });

export const RENT_DURATION = {
  [RENT_TYPE.weekly]: composeRentDurationOptions({
    unitLabel: 'Minggu',
  }),
  [RENT_TYPE.monthly]: composeRentDurationOptions({
    unitLabel: 'Bulan',
  }),
  [RENT_TYPE.quarterly]: composeRentDurationOptions({
    unitLabel: 'Bulan',
    step: 3,
  }),
  [RENT_TYPE.semiannually]: composeRentDurationOptions({
    unitLabel: 'Bulan',
    step: 6,
  }),
  [RENT_TYPE.yearly]: composeRentDurationOptions({
    unitLabel: 'Tahun',
    maxValue: 3,
  }),
};
