<template>
  <add-tenant-field
    rules="required"
    label="Hitungan Sewa"
    label-for="rentTypeSelect"
  >
    <bg-select
      id="rentTypeSelect"
      data-testid="rentTypeSelect"
      v-model="localValue"
      :options="options"
      :disabled="disabled"
      placeholder="Pilih hitungan sewa"
    />
  </add-tenant-field>
</template>

<script>
import { BgSelect } from 'bangul-vue';

import inputModelMixin from '@admin/pages/RoomAllotmentAddTenant/mixins/inputModel';

export default {
  name: 'AddTenantRentTypeField',

  mixins: [inputModelMixin],

  props: {
    options: {
      type: Array,
      required: true,
    },
  },

  components: {
    BgSelect,
  },
};
</script>
