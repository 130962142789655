/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  required,
  max,
  alpha,
  alpha_spaces,
  email,
  numeric,
  min,
} from 'vee-validate/dist/rules';
import { cleanNumber } from '@/utils/formatter';

extend('required', {
  validate(value = '') {
    if (value === '0' || value === 0) {
      return {
        required: true,
        valid: false,
      };
    }

    return required.validate(value);
  },
  message: 'Data wajib diisi',
  computesRequired: true,
});

extend('phone_format', {
  validate(value = '') {
    return /^08.*/g.test(value);
  },
  message: 'Nomor handphone harus diawali dengan 08',
});

extend('min', {
  ...min,
  params: ['length'],
  message: 'Minimal {length} karakter',
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'Maksimal {length} karakter',
});

extend('alpha', {
  ...alpha,
  message: 'Masukkan karakter alfabet',
});

extend('alpha_spaces', {
  ...alpha_spaces,
  message: 'Masukkan karakter alfabet',
});

extend('email', {
  ...email,
  message: 'Penulisan alamat email salah',
});

extend('numeric', {
  ...numeric,
  message: 'Hanya dapat diisi dengan angka',
});

extend('price_format', {
  validate(value = '') {
    const valueNumber = cleanNumber(value);
    return !!valueNumber && numeric.validate(valueNumber);
  },
  message: 'Hanya dapat diisi dengan angka',
});

/**
 * Valid when there is no `value`.
 * This can be used via `syncValue` method also.
 */
extend('error_sync_value', value => {
  if (value) {
    return value;
  }

  return true;
});

/**
 * These rules below will immediately trigger and show error
 * without checking anything
 *
 * usage example:
 * - normal state: `required|min:3`
 * - need to show not found error: `required|min:3|not_found`
 */
extend('not_found', () => 'Masukkan {_field_} yang terdaftar');
extend('not_selected', () => 'Pilih {_field_} yang terdaftar');

extend('contract_not_found', () => 'Contract ID tidak ditemukan.');
extend(
  'contract_is_not_terminated',
  () => 'Hanya bisa memasukkan Contract ID yang sudah diakhiri.'
);
