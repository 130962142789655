<template>
  <div class="add-tenant">
    <div v-if="isFetchingProperty" class="add-tenant__skeleton">
      <bg-skeleton width="266px" height="40px" class="mb-32" />
      <bg-skeleton width="70%" height="28px" class="mb-32" />
      <bg-skeleton width="100%" height="48px" class="mb-32" />

      <bg-skeleton width="164px" height="26px" class="mb-16" />
      <bg-skeleton width="100%" height="354px" />
    </div>

    <template v-else-if="property">
      <bg-text tag="h1" size="heading-2" class="add-tenant__page-title mb-32"
        >Tambah Penyewa</bg-text
      >
      <bg-text
        tag="h1"
        size="heading-4"
        class="add-tenant__page-subtitle mb-32"
        data-testid="addTenantPage_lbl"
      >
        {{ typeLabel }}
        <span class="add-tenant__page-subtitle-property-name">
          {{ typeLabelPostfix }} {{ propertyName }}
        </span>
      </bg-text>

      <validation-observer ref="formValidationObserver">
        <add-tenant-field
          rules="required"
          label-for="addTenantPropertyType_ddl"
          class="add-tenant__room-type-select mb-32"
        >
          <bg-select
            v-model="selectedListingId"
            menu-size="fit-to-trigger"
            options-label-key="name"
            options-value-key="song_id"
            data-testid="addTenantPropertyType_ddl"
            id="addTenantPropertyType_ddl"
            :options="roomTypes"
            :placeholder="roomTypeSelectPlaceholder"
            :disabled="isDisabledRoomTypeSelect"
          />
        </add-tenant-field>

        <slot name="form" :property="property" />
      </validation-observer>

      <slot />
    </template>

    <bg-empty-state
      v-else
      title="Data Tidak Ditemukan"
      illustration-name="no-internet"
      illustration-alt="Data tidak ditemukan"
      description="Terjadi kesalahan saat mendapatkan data properti."
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { BgText, BgSelect, BgSkeleton, BgEmptyState } from 'bangul-vue';
import { ValidationObserver } from 'vee-validate';

import propertyDetailAPI from '@admin/api/endpoints/property-detail';

import AddTenantField from '../AddTenantField';

export default {
  name: 'AddTenantWrapper',
  components: {
    BgText,
    BgSelect,
    BgSkeleton,
    BgEmptyState,
    ValidationObserver,
    AddTenantField,
  },
  props: {
    typeLabelPostfix: {
      type: String,
      default: 'di',
    },
    typeLabel: {
      type: String,
      default: '',
    },
    listingId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isFetchingProperty: false,
      property: null,
      selectedListingId: 0,
    };
  },
  watch: {
    selectedListingId(id) {
      this.$emit('update:listingId', id);
    },
  },
  created() {
    const isValidPropertyId =
      !!this.propertyId && /^[1-9][0-9]*$/.test(this.propertyId);

    if (isValidPropertyId) {
      this.fetchProperty();
    } else {
      this.$router.replace('/');
    }
  },
  computed: {
    propertyId() {
      return this.$route.params.propertyId || 0;
    },
    roomAllotmentCalendarViewPath() {
      return `/room-allotment/calendar-view?property_id=${this.propertyId}`;
    },
    propertyName() {
      return this.property?.name || '';
    },
    roomTypes() {
      return this.property?.listings || [];
    },
    isDisabledRoomTypeSelect() {
      return !this.roomTypes.length;
    },
    roomTypeSelectPlaceholder() {
      if (this.roomTypes.length) {
        return 'Pilih tipe kamar';
      }

      return 'Pilihan tipe kamar tidak tersedia';
    },
  },
  methods: {
    ...mapMutations('breadcrumb', ['setBreadcrumb']),

    async validateForm() {
      const validationObserver = this.$refs?.formValidationObserver;

      if (!validationObserver) {
        return false;
      }

      const isValid = await validationObserver.validate();

      return !!isValid;
    },
    setPageBreadcrumbs() {
      const breadcrumbs = [...this.$route.meta.breadcrumbs];

      const propertyBreadcrumbs = [
        {
          name: this.propertyName,
          url: `/property-detail/${this.propertyId}/overview`,
        },
        {
          name: 'Room Allotment',
          url: this.roomAllotmentCalendarViewPath,
        },
      ];

      breadcrumbs.splice(1, 2, ...propertyBreadcrumbs);

      this.setBreadcrumb(breadcrumbs);
    },
    async fetchProperty() {
      this.isFetchingProperty = true;
      this.property = null;
      this.selectedListingId = 0;

      try {
        const res = await propertyDetailAPI.getPropertyDetail(this.propertyId);
        const { status: isSuccess, data: propertyData } = res?.data || {};

        if (isSuccess && propertyData) {
          this.property = propertyData;
          this.setPageBreadcrumbs();
        } else {
          this.$toast.show('Gagal memuat data properti');
        }
      } catch (error) {
        this.$toast.show('Gagal memuat data properti');
        this.$error.report(error);
      } finally {
        this.$emit('property-fetched', this.property);
        this.isFetchingProperty = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./AddTenantWrapper.scss"></style>
