<template>
  <validation-provider
    tag="div"
    ref="validationProvider"
    :rules="rules"
    :immediate="immediate"
    :name="name"
    v-slot="{ errors }"
  >
    <bg-field
      v-bind="$attrs"
      :error="!!errors.length"
      :data-testid="dataTestid"
      :message="fieldMessage(errors)"
    >
      <slot />
    </bg-field>
  </validation-provider>
</template>

<script>
import { BgField } from 'bangul-vue';
import { ValidationProvider } from 'vee-validate';
import '@admin/pages/RoomAllotmentAddTenant/config/validationRules';

export default {
  name: 'AddTenantField',
  components: {
    BgField,
    ValidationProvider,
  },
  props: {
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    dataTestid: {
      type: String,
      default: '',
    },
  },
  methods: {
    fieldMessage(errors) {
      if (errors?.length) {
        return errors[0] || '';
      }

      return this.$attrs?.message || '';
    },
  },
};
</script>
