import AddTenantField from '../components/AddTenantField';

export default {
  components: {
    AddTenantField,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
    localValue(value) {
      this.$emit('input', value);
    },
  },
};
