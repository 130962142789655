<template>
  <bg-grid class="tenant-info-section">
    <bg-grid-item :col="12">
      <bg-text size="title-4">Informasi Penyewa</bg-text>
    </bg-grid-item>

    <template v-if="isAutofill">
      <bg-grid-item :col="6">
        <validation-observer
          ref="phoneInput"
          data-testid="phoneInputWrapper"
          tag="div"
        >
          <add-tenant-field-with-options
            id="inputPhone_txt"
            v-model="phoneNumber"
            v-on="phoneInputEvent"
            name="nomor handphone"
            label="Nomor Handphone"
            placeholder="Masukkan nomor handphone"
            :rules="phoneNumberRules"
            :disabled="searchByName.isLoading"
            :value-id="selectedTenantId"
            :is-loading="searchByPhone.isLoading"
            :is-loaded="searchByPhone.isLoaded"
            :options="searchByPhone.options"
            @select="handleTenantSelected($event, 'phone')"
          />
        </validation-observer>
      </bg-grid-item>

      <bg-grid-item :col="6">
        <validation-observer
          ref="nameInput"
          data-testid="nameInputWrapper"
          tag="div"
        >
          <add-tenant-field-with-options
            id="inputName_txt"
            v-model="tenantName"
            v-on="nameInputEvent"
            name="nama penyewa"
            label="Nama Penyewa"
            placeholder="Masukkan nama penyewa"
            :rules="nameRules"
            :disabled="searchByPhone.isLoading"
            :value-id="selectedTenantId"
            :is-loading="searchByName.isLoading"
            :is-loaded="searchByName.isLoaded"
            :options="searchByName.options"
            @select="handleTenantSelected($event, 'name')"
          />
        </validation-observer>
      </bg-grid-item>
    </template>

    <template v-else>
      <slot />

      <bg-grid-item :col="6">
        <add-tenant-field
          :rules="rules.phoneNumber"
          label="Nomor Handphone"
          label-for="inputPhoneNumber_txt"
        >
          <bg-input
            id="inputPhoneNumber_txt"
            v-model="phoneNumber"
            :disabled="isReadonly"
            placeholder="Masukkan nomor handphone"
          />
        </add-tenant-field>
      </bg-grid-item>

      <bg-grid-item :col="6">
        <add-tenant-field
          :rules="rules.tenantName"
          label="Nama Penyewa"
          label-for="inputName_txt"
        >
          <bg-input
            id="inputName_txt"
            v-model="tenantName"
            :disabled="isReadonly"
            placeholder="Masukkan nama penyewa"
          />
        </add-tenant-field>
      </bg-grid-item>
    </template>

    <bg-grid-item :col="6">
      <add-tenant-field
        :rules="rules.email"
        label="Email"
        label-for="inputEmail_txt"
      >
        <bg-input
          id="inputEmail_txt"
          v-model="email"
          :disabled="isDisableRelatedField"
          placeholder="Masukkan alamat email"
        />
      </add-tenant-field>
    </bg-grid-item>

    <bg-grid-item :col="6">
      <add-tenant-field
        :rules="rules.gender"
        label="Jenis Kelamin"
        label-for="inputGender"
      >
        <bg-select
          id="inputGender"
          data-testid="inputGender"
          v-model="gender"
          :disabled="isDisableRelatedField"
          :options="GENDER_OPTIONS"
          placeholder="Pilih jenis kelamin"
        />
      </add-tenant-field>
    </bg-grid-item>

    <bg-grid-item :col="6">
      <add-tenant-field
        :rules="rules.job"
        label="Pekerjaan"
        label-for="inputJob"
      >
        <bg-select
          id="inputJob"
          data-testid="inputJob"
          v-model="job"
          :disabled="isDisableRelatedField"
          :options="JOB_OPTIONS"
          placeholder="Pilih pekerjaan"
        />
      </add-tenant-field>
    </bg-grid-item>

    <template v-if="hasIdCardInput">
      <bg-grid-item :col="12">
        <add-tenant-id-card-uploader
          v-model="idCardUrl"
          id="idCardUploader"
          :disabled="isReadonly"
          :image-id="idCardImageId"
          :user-id="selectedTenantId"
          :hide-delete="isReadonly"
          @uploaded="idCardImageId = $event"
          @reset="idCardImageId = 0"
        />
      </bg-grid-item>
    </template>
  </bg-grid>
</template>

<script>
import axios from 'axios';
import _debounce from 'lodash/debounce';

import { BgGrid, BgGridItem, BgText, BgInput, BgSelect } from 'bangul-vue';
import { ValidationObserver } from 'vee-validate';

import userAPI from '@admin_endpoints/user';

import {
  GENDER_OPTIONS,
  JOB_OPTIONS,
} from '@admin/pages/RoomAllotmentAddTenant/constants/BookingData';

import AddTenantField from '@/_admin/pages/RoomAllotmentAddTenant/components/AddTenantField';
import AddTenantFieldWithOptions from '@/_admin/pages/RoomAllotmentAddTenant/components/AddTenantFieldWithOptions';
import AddTenantIdCardUploader from '@/_admin/pages/RoomAllotmentAddTenant/components/AddTenantIdCardUploader';

const DEFAULT_SEARCH_STATE = {
  isLoading: false,
  isLoaded: false,
  options: [],
};

const DEFAULT_INPUT_DATA = {
  phoneNumber: '',
  tenantName: '',
  email: '',
  gender: '',
  job: '',
  idCardUrl: '',
  idCardImageId: 0,
};

const SEARCH_TENANT_DELAY = 500;

export default {
  name: 'AddTenantInformationSection',
  components: {
    BgGrid,
    BgGridItem,
    BgText,
    BgInput,
    BgSelect,
    ValidationObserver,
    AddTenantField,
    AddTenantFieldWithOptions,
    AddTenantIdCardUploader,
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: false,
    },
    isEmailRequired: {
      type: Boolean,
      default: true,
    },
    initialData: {
      type: [Object, null],
      default: null,
    },
    isAutofill: {
      type: Boolean,
      default: false,
    },
    hasIdCardInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ...DEFAULT_INPUT_DATA,
      GENDER_OPTIONS,
      JOB_OPTIONS,
      searchByPhone: { ...DEFAULT_SEARCH_STATE },
      searchByName: { ...DEFAULT_SEARCH_STATE },
      selectedTenantData: null,
      unwatchFn: {
        phone: null,
        name: null,
      },
      isSetPhoneAsKey: true,
      searchCancelToken: null,
      rules: {
        phoneNumber: {
          required: true,
          numeric: true,
          phone_format: true,
          min: 8,
          max: 14,
        },
        tenantName: {
          required: true,
          alpha_spaces: true,
        },
        email: { required: this.isEmailRequired, email: true },
        gender: { required: true },
        job: { required: true },
      },
    };
  },
  computed: {
    isDisableRelatedField() {
      if (this.isReadonly) {
        return true;
      }

      if (this.isAutofill) {
        return !this.selectedTenantData;
      }

      return false;
    },
    selectedTenantId() {
      return this.initialData?.userId || this.selectedTenantData?.id || 0;
    },
    phoneInputEvent() {
      return this.composeAutofillEventHandler({
        focusHandler: this.watchPhoneChanges,
        blurHandler: () => {
          this.unwatch('phone');
        },
      });
    },
    nameInputEvent() {
      return this.composeAutofillEventHandler({
        focusHandler: this.watchNameChanges,
        blurHandler: () => {
          this.unwatch('name');
        },
      });
    },
    isPhoneNotFound() {
      return (
        this.isSetPhoneAsKey &&
        this.phoneNumber &&
        this.searchByPhone.isLoaded &&
        !this.searchByPhone.options.length
      );
    },
    isPhoneNotSelected() {
      return (
        this.isSetPhoneAsKey &&
        this.phoneNumber &&
        this.searchByPhone.isLoaded &&
        this.selectedTenantData?.phone_number !== this.phoneNumber
      );
    },
    phoneNumberRules() {
      return this.composeAutofillRules(this.rules.phoneNumber, {
        not_found: this.isPhoneNotFound,
        not_selected: this.isPhoneNotSelected,
      });
    },
    isNameNotFound() {
      return (
        this.tenantName &&
        this.searchByName.isLoaded &&
        !this.searchByName.options.length
      );
    },
    isNameNotSelected() {
      return (
        this.tenantName &&
        this.searchByName.isLoaded &&
        this.selectedTenantData?.phone_number !== this.phoneNumber
      );
    },
    nameRules() {
      return this.composeAutofillRules(this.rules.tenantName, {
        not_found: this.isNameNotFound,
        not_selected: this.isNameNotSelected,
      });
    },
  },
  watch: {
    initialData: {
      deep: true,
      handler(data) {
        Object.keys(DEFAULT_INPUT_DATA).forEach(dataKey => {
          this[dataKey] = data?.[dataKey] || DEFAULT_INPUT_DATA[dataKey];
        });
      },
    },
  },
  methods: {
    composeAutofillEventHandler({ blurHandler, focusHandler }) {
      if (this.isAutofill) {
        return {
          blur: blurHandler,
          focus: focusHandler,
        };
      }

      return {};
    },
    composeAutofillRules(baseRules, additionalRules) {
      if (this.isAutofill) {
        return { ...baseRules, ...additionalRules };
      }

      return baseRules;
    },
    unwatch(key) {
      if (this.unwatchFn?.[key]) {
        this.unwatchFn[key]();
      }
    },
    watchPhoneChanges() {
      this.unwatch('phone');

      if (!this.isSetPhoneAsKey) {
        return;
      }

      this.unwatchFn.phone = this.$watch('phoneNumber', async phoneNumber => {
        this.searchByName = { ...DEFAULT_SEARCH_STATE };
        this.searchByPhone.options = [];
        this.searchByPhone.isLoaded = false;
        this.cancelSearchTenants();

        this.setTenant({
          tenantData: { phone_number: phoneNumber },
          key: 'phone',
          unwatchKey: 'name',
        });

        await this.$nextTick();
        const isValid = await this.$refs?.phoneInput?.validate();

        if (isValid && phoneNumber) {
          this.searchTenantsBy('phone', phoneNumber);
        }
      });
    },
    watchNameChanges() {
      this.unwatch('name');

      this.unwatchFn.name = this.$watch('tenantName', async tenantName => {
        this.searchByPhone = { ...DEFAULT_SEARCH_STATE };
        this.searchByName.options = [];
        this.searchByName.isLoaded = false;
        this.isSetPhoneAsKey = true;
        this.cancelSearchTenants();

        this.setTenant({
          tenantData: { name: tenantName },
          key: 'name',
          unwatchKey: 'phone',
        });

        await this.$nextTick();
        const isValid = await this.$refs?.nameInput?.validate();

        if (isValid && tenantName) {
          this.searchTenantsBy('name', tenantName);
        }
      });
    },
    setTenant({
      tenantData = null,
      key = '',
      unwatchKey = '',
      selectedFrom = '',
    }) {
      if (unwatchKey) {
        this.unwatch(unwatchKey);
      }

      if (selectedFrom === 'name' && !tenantData?.phone_number) {
        this.isSetPhoneAsKey = false;
      }

      this.phoneNumber = tenantData?.phone_number || '';
      this.tenantName = tenantData?.name || '';
      this.email = tenantData?.email || '';
      this.job = tenantData?.job || '';
      this.gender = tenantData?.gender || '';
      this.idCardUrl = tenantData?.identity_image?.medium || '';
      this.idCardImageId = tenantData?.identity_image?.id || 0;

      this.selectedTenantData = key ? null : tenantData || null;
    },
    handleTenantSelected(tenantData, type) {
      this.unwatch('phone');
      this.unwatch('name');

      this.setTenant({ tenantData, unwatchKey: type, selectedFrom: type });

      const setValue = (...params) => this.setSearchValue(type, ...params);

      setValue('isLoaded', false);

      this.$nextTick(() => {
        this.watchNameChanges();
        this.watchPhoneChanges();
      });
    },
    setSearchValue(type, key, value) {
      const state = type === 'phone' ? 'searchByPhone' : 'searchByName';

      this[state][key] = value;
    },
    composeUserOptions(users, type) {
      return users.map(user => {
        let label = '';

        if (type === 'phone') {
          label = `${user.phone_number} | ${user.name}`;
        } else {
          label = `${user.name} | ${user.phone_number || '-'}`;
        }

        return {
          ...user,
          label,
        };
      });
    },
    cancelSearchTenants() {
      if (this.searchCancelToken) {
        this.searchCancelToken.cancel();
      }

      const { CancelToken } = axios;
      this.searchCancelToken = CancelToken.source();
    },
    getCurrentSearchValue(type) {
      return type === 'phone' ? this.phoneNumber : this.tenantName;
    },
    searchTenantsBy: _debounce(async function (type, search) {
      this.cancelSearchTenants();

      if (search !== this.getCurrentSearchValue(type)) {
        return;
      }

      const setValue = (...params) => this.setSearchValue(type, ...params);

      this.selectedTenantData = null;

      setValue('isLoading', true);
      setValue('options', []);
      setValue('isLoaded', false);

      try {
        const res = await userAPI.getUsers(
          { type, search },
          this.searchCancelToken.token
        );

        const users = res?.data?.users || [];

        setValue('options', this.composeUserOptions(users, type));
        setValue('isLoaded', true);

        setValue('isLoading', false);
      } catch (error) {
        if (axios.isCancel(error)) {
          setValue('isLoaded', false);
          setValue('isLoading', false);
        } else {
          const errorMessage =
            error?.response?.data?.error ||
            'Terjadi kesalahan saat mencari data tenant';
          this.$toast.show(errorMessage);
          setValue('isLoaded', true);
          setValue('isLoading', false);
        }
      }
    }, SEARCH_TENANT_DELAY),

    // Called from parent to get all inputed values in this component
    getValues() {
      return {
        user_id: this.selectedTenantId,
        contact_phone: this.phoneNumber,
        contact_name: this.tenantName,
        contact_email: this.email,
        contact_gender: this.gender,
        contact_job: this.job,
        contact_identity: this.idCardImageId,
      };
    },
  },
};
</script>
