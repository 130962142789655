<template>
  <add-tenant-field
    rules="required"
    label="Durasi Sewa"
    label-for="rentDurationSelect"
  >
    <bg-select
      id="rentDurationSelect"
      data-testid="rentDurationSelect"
      v-model="localValue"
      :disabled="disabled"
      :options="durationOptions"
      placeholder="Pilih durasi sewa"
    />
  </add-tenant-field>
</template>

<script>
import { BgSelect } from 'bangul-vue';
import { RENT_DURATION } from '@admin/pages/RoomAllotmentAddTenant/constants/BookingData';
import inputModelMixin from '@admin/pages/RoomAllotmentAddTenant/mixins/inputModel';

export default {
  name: 'AddTenantRentDurationField',

  mixins: [inputModelMixin],

  components: {
    BgSelect,
  },

  props: {
    rentType: {
      type: [Number, String],
      default: '',
    },
  },

  computed: {
    durationOptions() {
      return RENT_DURATION[this.rentType] || [];
    },
  },
};
</script>
