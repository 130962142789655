<template>
  <add-tenant-field
    rules="required"
    :label="label"
    :label-for="id"
    :message="message"
  >
    <bg-datepicker
      :id="id"
      v-model="localValue"
      :data-testid="id"
      :disabled="disabled"
      :disabled-dates="disabledDates"
      placeholder="Pilih tanggal"
    />
  </add-tenant-field>
</template>

<script>
import { BgDatepicker } from 'bangul-vue';

import inputModelMixin from '@admin/pages/RoomAllotmentAddTenant/mixins/inputModel';

export default {
  name: 'AddTenantDateField',

  mixins: [inputModelMixin],

  components: {
    BgDatepicker,
  },

  props: {
    value: {
      type: [Date, String],
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
