<template>
  <add-tenant-field
    rules="required"
    label="Harga Sewa"
    label-for="rentPrice_txt"
  >
    <bg-input id="rentPrice_txt" :value="price" disabled readonly prefix="Rp" />
  </add-tenant-field>
</template>

<script>
import { BgInput } from 'bangul-vue';

import inputModelMixin from '@admin/pages/RoomAllotmentAddTenant/mixins/inputModel';
import { currencyFormatter } from '@/utils/formatter';

export default {
  name: 'AddTenantRentPriceField',

  mixins: [inputModelMixin],

  components: {
    BgInput,
  },

  computed: {
    price() {
      return currencyFormatter(this.value || 0);
    },
  },
};
</script>
