<template>
  <add-tenant-field
    :class="idCardUploaderClasses"
    ref="field"
    label="Foto KTP"
    rules="error_sync_value"
    immediate
    optional
    optional-label-text="(Opsional)"
    :label-for="id"
  >
    <bg-image-uploader
      :id="id"
      :progress="uploadProgress"
      :is-error="!!uploadErrorMessage"
      :initial-image-url="value"
      :show-delete="!hideDelete"
      trigger-title="Upload di sini"
      @upload="upload"
      @reupload="upload"
      @delete="reset"
    />
  </add-tenant-field>
</template>

<script>
import { BgImageUploader } from 'bangul-vue';

import userAPI from '@admin_endpoints/user';

import AddTenantField from '@admin/pages/RoomAllotmentAddTenant/components/AddTenantField';

const MAX_UPLOAD_PROGRESS = 98;
const DEFAULT_UPLOAD_ERROR_MESSAGE =
  'Terjadi kesalahan dalam mengunggah Foto KTP';

export default {
  name: 'AddTenantIdCardUploader',
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    imageId: {
      type: Number,
      default: 0,
    },
    userId: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BgImageUploader,
    AddTenantField,
  },
  data() {
    return {
      imageUrl: this.value,
      imageFile: null,
      uploadProgress: 0,
      uploadErrorMessage: '',
    };
  },
  computed: {
    isUploaded() {
      return !!this.value && !!this.id;
    },
    idCardUploaderClasses() {
      const base = 'id-card-uploader';
      return [base, { [`${base}--disabled`]: this.disabled }];
    },
  },
  watch: {
    value(imageUrl) {
      this.imageUrl = imageUrl;
    },
    imageUrl(url) {
      this.$emit('input', url);
    },
    isUploaded: {
      immediate: true,
      handler(isUploaded) {
        if (isUploaded) {
          this.uploadProgress = 100;
        }
      },
    },
    userId(userId) {
      if (!userId) {
        this.reset();
      }
    },
  },
  methods: {
    async upload(file) {
      this.imageFile = file;
      this.imageUrl = '';
      this.uploadProgress = 0;
      this.$emit('reset');

      try {
        /* istanbul ignore next */
        const onUploadProgress = progressEvent => {
          const { loaded, total } = progressEvent;
          this.uploadProgress = Math.floor(
            (loaded * MAX_UPLOAD_PROGRESS) / total
          );
        };

        const formData = new FormData();

        formData.append('media', file);
        formData.append('type', 'e_ktp');
        formData.append('from', 'pms_new_booking');
        formData.append('description', 'photo_identity');
        formData.append('user_id', this.userId);

        const res = await userAPI.uploadIdentityCard(formData, {
          onUploadProgress,
        });

        const { status, media, meta } = res?.data || {};

        if (status && media) {
          const imageUrl = media.url.medium;
          this.imageUrl = imageUrl;
          this.$emit('input', imageUrl);
          this.$emit('uploaded', media.id);
          this.uploadProgress = 100;
        } else {
          const errorMessage = meta?.message || DEFAULT_UPLOAD_ERROR_MESSAGE;
          this.setError(errorMessage);
          this.uploadProgress = 0;
        }
      } catch (error) {
        this.$error.report(error);
        this.setError(DEFAULT_UPLOAD_ERROR_MESSAGE);
        this.uploadProgress = 0;
      }
    },
    reset() {
      this.imageFile = null;
      this.imageUrl = '';
      this.setError('');
      this.$emit('reset');
    },
    setError(error) {
      const validator = this.$refs.field.$refs.validationProvider;

      this.uploadErrorMessage = error;
      validator.syncValue(error);

      if (error) {
        validator.validate();
      } else {
        validator.reset();
      }
    },
  },
};
</script>

<style lang="scss" src="./AddTenantIdCardUploader.scss" scoped></style>
